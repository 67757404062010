<template>
  <div>
    <v-footer
      v-if="replyMessage"
      absolute
      height="auto"
      class="talk-reply-message"
    >
      <div>
        <strong>Responder: </strong>
        <span>
          {{ replyMessage.text | stringlength(180) }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon ripple @click="replyMessage = null">
        <v-icon>close</v-icon>
      </v-btn>
    </v-footer>
    <v-footer v-if="talk.status > 2" absolute height="65" class="pa-3">
      <span style="display: flex; gap: 5px; justify-content: center; width: 100%;">
        <span style="text-align: left;" class="hidden-xs-only hidden-sm-only">
          <strong>Atendimento #{{ talk.id }} encerrado </strong><br>
          <span> Em {{ talk.closed_at | moment('DD/MM/YYYY HH:mm') }}</span>
        </span>
        <v-btn
          @click="createNewTalk(talk.contact)"
          color="green" class="white--text"
        >
          <v-icon>message</v-icon>
          <span class="pl-1">Iniciar novo atendimento</span>
        </v-btn>
      </span>
    </v-footer>
    <v-footer v-else-if="!talk.operator_id" absolute height="65" class="pa-3">
      <div style="width: 100%; text-align: center;">
        <span>{{ talk.contact.name }} </span>
        <span
          v-if="talk.status == 1 && (talk.contact && talk.contact.type == 1)"
          v-t="'modules.talks.pages.talkView.talk.messages.status.chatbot'"
        ></span>
        <span
          v-if="talk.status == 2 || (talk.contact && talk.contact.type == 2)"
          v-t="'modules.talks.pages.talkView.talk.messages.status.awaiting'"
        ></span>
        <v-btn
          color="green" class="white--text"
          @click="$WiApiPost({
            uri: 'talk/talk/' + talk.id + '/operator',
            config: {
              msgAsk: true,
              msgLoad: true,
              msgSucess: true,
              msgError: true
            }
          })"
        >
          <v-icon>slideshow</v-icon>
          <span
            class="pl-1"
            v-t="'modules.talks.pages.talkView.talk.labels.startTalk'"
          ></span>
        </v-btn>
      </div>
    </v-footer>
    <v-footer v-else-if="talk.window && talk.window.status && talk.window.status == 'waiting-template'" absolute height="65" class="pa-3">
      <span>
        <span v-t="'modules.talks.pages.talkView.talk.messages.status.talking'"></span>
        <strong> {{ talk.operator.name }}</strong>
        <v-btn
          @click="selectTemplate()"
          color="primary"
        >
          <span>
            Selecione um template
          </span>
        </v-btn>
      </span>
    </v-footer>
    <v-footer
      v-else-if="talk.operator_id == $wiUser.id"
      absolute height="70" class="pa-0"
      style="border-top: solid 1px lightgrey;"
      :style="{'background-color': sendNoteMode ? '#FDE68A' : ''}"
    >
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="display: flex;">
          <v-btn icon ripple small style="margin: 3px 8px;" @click="openMessageTemplatesList()">
            <v-icon>message</v-icon>
          </v-btn>
          <v-menu offset-y :loading="aiLoading" class="hidden-xs-only hidden-sm-only" lazy>
            <template v-slot:activator="{ on }">
              <v-btn icon ripple v-on="on" small style="margin: 3px 8px;" :loading="aiLoading" class="hidden-xs-only hidden-sm-only">
                <v-icon>emoji_objects</v-icon>
              </v-btn>
            </template>
            <MessageAIMenu :message="message" @onSelect="aiOptionAction"></MessageAIMenu>
          </v-menu>
        </div>
        <div style="display: flex;">
          <EmojiMenuDialog @onSelect="emojiSelector"></EmojiMenuDialog>
          <v-dialog v-model="messageEditorDialog" width="500" persistent lazy>
            <template v-slot:activator="{ on }">
              <v-btn
                icon small style="margin: 3px 8px;"
                v-on="on"
                class="hidden-xs-only hidden-sm-only"
              >
                <v-icon>expand</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title primary-title style="padding: 0 15px;">
                <v-icon>edit</v-icon>
                <strong
                  class="ml-2"
                  v-t="'modules.talks.pages.talkView.talk.messageDialog.title'"
                ></strong>
                <v-spacer></v-spacer>
                <v-btn icon @click="messageEditorDialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="padding: 15px 15px 0 15px;">
                <v-textarea 
                  v-model="message" 
                  class="talk-input" 
                  :class="{'talk-footer__input--dark': $wiDark}" 
                  type="text" 
                  :placeholder="$t('modules.talks.pages.talkView.talk.labels.inputPlaceholder')"
                  @keydown="inputHandler" 
                  @keyup="onKeyUp"
                  rows="10"
                  outline
                  ref="talkFooterTextArea"
                  :autofocus="true"
                  single-line
                  hide-details
                ></v-textarea>
              </v-card-text>
              <v-card-actions v-if="messageEditorDialog">
                <v-btn icon ripple style="margin: 8px 0 8px 8px;" @click="openMessageTemplatesList()">
                  <v-icon>message</v-icon>
                </v-btn>
                <EmojiMenuDialog @onSelect="emojiSelector"></EmojiMenuDialog>
                <v-btn icon ripple style="margin: 8px 0 8px 0px;" @click="openTalkSchedule()">
                  <v-icon>event</v-icon>
                </v-btn>
                <v-menu offset-y :loading="aiLoading" lazy>
                  <template v-slot:activator="{ on }">
                    <v-btn icon ripple v-on="on" style="margin: 8px 8px 8px 0px;" :loading="aiLoading">
                      <v-icon>emoji_objects</v-icon>
                    </v-btn>
                  </template>
                  <MessageAIMenu :message="message" @onSelect="aiOptionAction"></MessageAIMenu>
                </v-menu>
                <v-btn outline block :disabled="!message" @click="prepareMessage()">
                  <span v-t="'modules.talks.pages.talkView.talk.messageDialog.send'"></span>
                  <v-icon class="ml-3">send</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <textarea 
        v-model="message" 
        class="talk-input" 
        :class="{'talk-footer__input--dark': $wiDark}" 
        type="text" 
        :placeholder="sendNoteMode ? 'Anotações são particulares e visíveis apenas para você e seu time' : $t('modules.talks.pages.talkView.talk.labels.inputPlaceholder')" 
        @keydown="inputHandler" 
        @keyup="onKeyUp"
        rows="3"
        ref="talkFooterTextArea"
        :autofocus="true"
      ></textarea>
      <div style="display: flex; align-items: center; justify-content: center; max-width: 130px; border-left: solid 1px lightgrey; margin-left: 4px;">
        <div>
          <v-btn
            icon ripple small
            :disabled="!message"
            @click="prepareMessage()"
            style="margin: 3px 8px;"
          >
            <v-icon>send</v-icon>
          </v-btn>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; border-left: solid 1px lightgrey;">
          <div style="display: flex; align-items: center; justify-content: center;">
            <v-speed-dial
              direction="top"
              :absolute="false"
              v-model="buttonNewFile"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator>
                <v-btn icon ripple small style="margin: 1px 4px;">
                  <v-icon v-if="!buttonNewFile">
                    attachment
                  </v-icon>
                  <v-icon v-else>
                    close
                  </v-icon>
                </v-btn>
              </template>
              <v-btn fab dark small color="purple" @click="openFileDialog(2)">
                <v-icon x-large>
                  insert_photo
                </v-icon>
              </v-btn>
              <v-btn fab dark small color="red" @click="openFileDialog(3)">
                <v-icon x-large>
                  play_circle_filled
                </v-icon>
              </v-btn>
              <v-btn fab dark small color="blue" @click="openFileDialog(5)">
                <v-icon x-large>
                  insert_drive_file
                </v-icon>
              </v-btn>
            </v-speed-dial>
            <div style="width: 36px; display: flex; align-items: center; justify-content: center;">
              <TalkFooterAudio></TalkFooterAudio>
            </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: center;">
            <v-tooltip top lazy>
              <template slot="activator">
                <v-badge right overlap offset-x="22" offset-y="24" color="primary">
                  <template v-slot:badge>
                    <span v-if="talk.assets && talk.assets.schedules && talk.assets.schedules.length > 0" class="white--text">
                      <span v-if="talk.assets.schedules.length > 9">+9</span>
                      <span v-else v-text="talk.assets.schedules.length"></span>
                    </span>
                  </template>
                  <v-btn :disabled="!talk.assets" icon ripple small style="margin: 1px 4px;" @click="openTalkSchedule()">
                    <v-icon>event</v-icon>
                  </v-btn>
                </v-badge>
                
              </template>
              <span>
                <span>Agendar envio da mensagem</span>
              </span>
            </v-tooltip>
            <v-tooltip top lazy>
              <template slot="activator">
                <v-btn icon ripple small style="margin: 1px 4px;" @click="sendNoteMode = !sendNoteMode">
                  <v-icon v-if="!sendNoteMode" color="orange">post_add</v-icon>
                  <v-icon v-else color="orange">cancel_presentation</v-icon>
                </v-btn>
              </template>
              <span>
                <span v-if="!sendNoteMode">Adicionar nota interna</span>
                <span v-else>Fechar nota interna</span>
              </span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-footer>
    <v-footer v-else absolute height="65" class="pa-3">
      <span>
        <span v-t="'modules.talks.pages.talkView.talk.messages.status.talking'"></span>
        <strong> {{ talk.operator.name }}</strong>
        <v-btn
          @click="$WiApiPost({
            uri: 'talk/talk/' + talk.id + '/operator',
            config: {
              msgAsk: true,
              msgLoad: true,
              msgSucess: true,
              msgError: true
            }
          })"
          color="orange"
        >
          <v-icon>compare_arrows</v-icon>
          <span class="pl-1">Assumir atendimento</span>
        </v-btn>
      </span>
    </v-footer>
    <TalkFileDialog></TalkFileDialog>
  </div>
</template>
<script>
  import talkViewMixin from '../talkViewMixin'
  import { mapState, mapActions } from 'vuex'
  import { Auth } from '@/default/service/Auth'
  import {callApi} from '@/default/service/Api'
  import TalkFileDialog from '@/modules/talk/pages/TalkView/Talk/TalkFileDialog'
  import TalkFooterAudio from '@/modules/talk/pages/TalkView/Talk/TalkFooterAudio'
  import EmojiMenuDialog from './EmojiMenuDialog.vue'
  import MessageAIMenu from './MessageAIMenu.vue'
  export default {
    name: 'TalkFooter',
    data: () => ({
      message: '',
      buttonNewFile: false,
      // messageEditorDialog: false,
      emojiSelectorDialog: false,
      talkMessageTemplateId: null,
      aiLoading: false,
      sendNoteMode: false
    }),
    computed: {
      ...mapState('Talk', ['talk', 'messageText']),
      replyMessage: {
        get () {
          return this.$store.state.Talk.replyMessage
        },
        set (replyMessage) {
          this.$store.commit('Talk/SET_REPLY_MESSAGE', replyMessage)
        }
      },
      messageEditorDialog: {
        get () {
          return this.$store.state.Talk.messageEditorDialog
        },
        set (messageEditorDialog) {
          this.$store.commit('Talk/SET_MESSAGE_EDITOR_DIALOG', messageEditorDialog)
        }
      }
    },
    methods: {
      ...mapActions('Talk', ['openFileDialog', 'setMessage', 'sendMessage']),
      inputHandler (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault()
          this.prepareMessage(this.message)
        }
      },
      onKeyUp: function () {
        if (this.message == '/m') {
          this.message = ''
          this.openMessageTemplatesList()
        }
      },
      prepareMessage: function () {
        if (this.message && this.message !== '') {
          var message = this.createMessage()
          this.setMessage({ talkMessage: message })
          this.sendMessage(message)
          this.$forceUpdate()
          this.$emit('scroll', 0)
          this.clearMessageInput()
        }
      },
      clearMessageInput: function () {
        this.message = ''
        this.replyMessage = null
      },
      createMessage: function () {
        var message = {
          'talk_id': this.talk.id,
          'conversation_id': this.talk.talk_contact_id,
          'text': this.message,
          'text_html': this.message,
          'uuid': this.$UUID(),
          'from_me': true,
          'type': this.sendNoteMode ? 17 : 1,
          'talk_message_id': this.replyMessage ? this.replyMessage.id : null,
          'message': this.replyMessage || null,
          'loading': true,
          'ack': this.sendNoteMode ? 9 : 0,
          'person': Auth.user(),
          'external_time_formated': 'Agora',
          'talk_message_template_id': this.talkMessageTemplateId
        }
        return message
      },
      onAudioResult (data) {
        this.send('Hello World!', data)
        // console.log('The blob data:', data);
        // console.log('Downloadable audio', window.URL.createObjectURL(data));
        // var reader = new window.FileReader()
        // reader.readAsDataURL(data)
        // reader.onloadend = function () {
        //   var base64 = reader.result;
        //   base64 = base64.split(',')[1];
        //   console.log(base64)
        // }
      },
      send: function (fieldName, file, metadata, load, error, progress, abort) {
        let formData = new FormData()
        console.log(progress)
        console.log(abort)
        var infos = {type: file.type, size: file.size}
        infos = Object.assign(infos, {talk_id: this.$route.params.id, talkFileType: 4, audioMessage: true})
        formData.append('files[0]', file, 'hello-world-123.')
        formData.append('infos[0]', JSON.stringify(infos))
        callApi.post({
          uri: 'system/file',
          msgLoad: false,
          data: formData,
          headers: {headers: {'Content-Type': 'multipart/form-data'}},
          sucess: (data) => {
            if (data.status === 201 && data.data.status === 'success') {
              load(data.data.data[0].id)
            } else {
              error(this.$t('messages.error.text'))
            }
          },
          error: (err) => {
            console.log(err)
            error(this.$t('messages.error.text'))
          }
        })
      },
      setAutoFocus: function () {
        if (this.$refs.talkFooterTextArea) {
          this.$refs.talkFooterTextArea.focus()
        }
      },
      openMessageTemplatesList: function () {
        this.$WiListDialog({
          wiConfig: 'talk-message-template',
          callback: selected => {
            this.talkMessageTemplateId = selected.id
            this.message = selected.text_with_variables || selected.text || ''
          },
          filter: {
            talk_contact_id: this.talk.talk_contact_id,
            talk_channel_id: this.talk.talk_channel_id
          }
        })
      },
      emojiSelector: function (emoji) {
        if (emoji) {
          this.message += emoji
        }
      },
      setMessageText: function (text) {
        if (text && text != '') {
          this.message = text
        } 
      },
      selectTemplate: function () {
        this.$WiListDialog({
          wiConfig: 'talk-channel-template',
          mode: 'select',
          filter: {
            channel_id: this.talk.talk_channel_id
          },
          callback: selected => {
            this.sendMessage({
              'talk_id': this.talk.id,
              'conversation_id': this.talk.talk_contact_id,
              'text': JSON.stringify(selected),
              'uuid': this.$UUID(),
              'from_me': true,
              'type': 14,
              'loading': true,
              'ack': 0,
              'person': Auth.user(),
              'external_time_formated': 'Agora'
            })
            this.$forceUpdate()
            this.$emit('scroll', 0)
            this.clearMessageInput()
          }
        })
      },
      aiOptionAction: function (action) {
        console.log(action)
        this.aiLoading = true
        this.$WiApiPost({
          uri: 'talks/talk/ai-assistent',
          data: {
            action: action,
            talk_id: this.talk.id,
            talk_contact_id: this.talk.talk_contact_id,
            message: this.message
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true
          },
          callback: ({ sucess }) => {
            if (sucess && sucess.status == 'success' && sucess?.text) {
              this.message = sucess.text
            }
            this.aiLoading = false
          }
        })
      },
      openTalkSchedule: function () {
        if (this.talk.assets.schedules.length > 0) {
          this.$WiListDialog({
            wiConfig: 'talk-schedule',
            filter: {
              talk_contact_id: this.talk.talk_contact_id
            },
            wiEditData: {
              text: this.message,
              contact: this.talk.contact,
              talk_contact_id: this.talk.talk_contact_id,
              talk_channel_id: this.talk.talk_channel_id
            }
          })
        } else {
          this.$WiEditDialog({
            wiConfig: 'talk-schedule',
            data: {
              text: this.message,
              contact: this.talk.contact,
              talk_contact_id: this.talk.talk_contact_id,
              talk_channel_id: this.talk.talk_channel_id
            },
            onSubmit: () => {
              this.$emit('refresh')
            },
            redirectOnSubmit: false
          })
        }
      }
    },
    mounted: function () {
      this.setAutoFocus()
      this.emojiSelector()
    },
    watch: {
      messageText: 'setMessageText'
    },
    components: {
      TalkFileDialog,
      TalkFooterAudio,
      EmojiMenuDialog,
      MessageAIMenu
    },
    mixins: [talkViewMixin]
  }
</script>
<style scoped>
  .talk-input {
    min-height: 40px !important;
    padding: 0;
    background: white;
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
  }
  .talk-reply-message {
    margin-bottom: 70px !important;
    border-bottom: 1px solid rgb(194, 194, 194);
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
  }
  .talk-footer__input--dark {
    background-color: #313131 !important;
    color: #fff;
  }
  .vue-audio-recorder {
    width: 45px !important;
    height: 34px !important;
  }
  emoji-picker {
    --emoji-size: 1.5rem;
    --emoji-font-family: "Apple Color Emoji"
  }
  .talk-footer__ai-option-item >>> .v-list__tile:not(.v-list__tile--avatar) {
    height: 28px !important;
  }
</style>