import { Model } from '@/default/model/Model'

export class TalkMessageTemplate extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '123eab35-8984-4551-bd15-5b054b2ac8bf'
    this.name = 'talk-message-template'
    this.title = {
      list: 'Mensagens rápidas',
      edit: {
        insert: 'Criar nova mensagem',
        update: 'Atualizar mensagem'
      }
    }
    this.apiUrl = 'talks/message/template'
    this.icon = 'message'
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Pesquise pelo nome da mensagem',
      fields: ['name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '988px'
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkMessages/TalkMessageTemplates')
    this.listFields = {
      id: this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      name: this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      text: this.fieldList({
        value: 'text',
        text: 'Mensagem',
        sortable: false
      })
    }
    this.editFields = {
      header1: this.fieldSubHeader({
        text: 'Informe o nome e texto da mensagem.'
      }),
      name: this.fieldText({
        value: 'name',
        text: 'Nome',
        placeholder: 'Ex: Mensagem de boas vindas',
        required: true,
        size: ['xs12', 'sm12', 'md8', 'ls8', 'xl8']
      }),
      visibility: this.fieldSelect({
        value: 'visibility',
        text: 'Visível para:',
        required: true,
        options: [
          { value: 'all', text: 'Todos' },
          { value: 'person', text: 'Apenas para mim' }
        ],
        size: ['xs12', 'sm12', 'md4', 'ls4', 'xl4']
      }),
      header2: this.fieldSubHeader({
        text: 'Use as variáveis abaixo para personalizar a mensagem.'
      }),
      header3: this.fieldSubHeader({
        text: 'Variáveis do contato: {{name}}, {{phone}}, {{email}}, {{firstName}}, {{fullName}}',
        style: { background: '#b0deb0', 'min-height': '35px' }
      }),
      header4: this.fieldSubHeader({
        text: 'Variáveis do operador: {{operatorName}}, {{operatorFirstName}}',
        style: { background: '#b0deb0', 'min-height': '35px' }
      }),
      header5: this.fieldSubHeader({
        text: 'Variáveis do canal: {{channelNumber}}, {{channelWhatsAppLink}}',
        style: { background: '#b0deb0', 'min-height': '35px' }
      }),
      text: this.fieldTextArea({
        value: 'text',
        text: 'Texto',
        placeholder: 'Ex: Olá, seja bem vindo ao nosso atendimento. Em que posso ajudar?',
        required: true
      })
    }
    this.setFields(this.listFields)
    this.setFields(this.editFields)
  }
}
